import React, { useState } from 'react';
import axios from 'axios';
import { usePayment } from '../context/PaymentContext';

const PaymentForm = () => {
    const [amount, setAmount] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const { setPaymentDetails, setIsModalOpen } = usePayment();

    const handlePayment = async () => {
        setIsProcessing(true);
        try {
            const response = await axios.post('/api/v1/payment/process', {
                currency: 'MYR',
                clientEmail,
                products: [
                    { name: 'Online Product', price: parseFloat(amount) * 100 }, // Convert to cents
                ],
            });

            if (response.data.status === 'success') {
                setPaymentDetails({
                    purchaseId: response.data.purchaseId,
                    paymentUrl: response.data.paymentUrl,
                });
                setIsModalOpen(true); // Open the modal
            } else {
                alert('Payment error: ' + response.data.error);
            }
        } catch (error) {
            console.error('Payment error:', error.message);
            alert('Payment failed. Please try again.');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="payment-form">
            <h2>Make a Payment</h2>
            <input
                type="text"
                placeholder="Amount (MYR)"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
            <input
                type="email"
                placeholder="Client Email"
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
            />
            <button onClick={handlePayment} disabled={isProcessing}>
                {isProcessing ? 'Processing...' : 'Pay Now'}
            </button>
        </div>
    );
};

export default PaymentForm;