import React, { createContext, useContext, useState } from 'react';

const PaymentContext = createContext();

export const usePayment = () => useContext(PaymentContext);

export const PaymentProvider = ({ children }) => {
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <PaymentContext.Provider value={{ paymentDetails, setPaymentDetails, isModalOpen, setIsModalOpen }}>
            {children}
        </PaymentContext.Provider>
    );
};