import React from "react";
import { usePayment } from "../context/PaymentContext";

const PaymentModal = () => {
  const { paymentDetails, isModalOpen, setIsModalOpen } = usePayment();

  if (!isModalOpen || !paymentDetails) return null;

  const handleOpenInNewTab = () => {
    window.open(paymentDetails.paymentUrl, "_blank");
    setIsModalOpen(false); // Close modal after opening the link
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={() => setIsModalOpen(false)}>
          ×
        </button>
        <h2>Complete Your Payment</h2>
        <p>
          If the payment page doesn't load below,{" "}
          <button onClick={handleOpenInNewTab}>click here</button> to open it in
          a new tab.
        </p>
        <iframe
          src={paymentDetails.paymentUrl}
          style={{ width: "100%", height: "500px", border: "none" }}
          title="Payment Page"
        ></iframe>
      </div>
    </div>
  );
};

export default PaymentModal;
