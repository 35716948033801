import React from 'react';
import PaymentForm from './components/PaymentForm';
import { PaymentProvider } from './context/PaymentContext';
import './assets/css/style.css';
import PaymentModal from './components/PaymentModal';

function App() {
    return (
        <PaymentProvider>
            <div className="app-container">
                <h1>CHIP Payment Modal</h1>
                <PaymentForm />
                <PaymentModal />
            </div>
        </PaymentProvider>
    );
}

export default App;